import "../styles/moya.css";
//import moyag from "../gifs/moya.gif"
//import meow from "../gifs/meow.gif"

/*function MoyaPage() {
    return (
        <body>

            <p>
                <img src={moyag} alt="w" width="200" height="200" padding="25" />
                i love you :)
                <img src={moyag} alt="w" width="200" height="200" padding="25" />
            </p>
            <div>
                <img src={meow} alt="w" width="1000" height="600" />
            </div>
        </body>
    )
}*/


function Moya() {
    window.location.href = "https://moyalucy.portfoliobox.net"
    /*return (
        <>
            <MoyaPage />
        </>
    );*/
}

export default Moya;